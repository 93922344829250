import React from "react";

import { Intro, Footer, Whatsapp } from "../../components";
import storageIMG from "../../images/bannerProds/storage.webp";

import mezzanineIMG from "../../images/products/mezzanineP.webp";

import racking2 from "../../images/products/rackingP.webp";
import shelving from "../../images/products/shelvingP.webp";

import "../Products/Products.css";
import Product from "../../components/ProductTabs/Product";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Constants from "../../constants";

function Racking() {
  document.body.classList.remove("no-scroll");
  const racking = {
    title: "Storage Lockers",
  };

  return (
    <>
      <Whatsapp />
      <Intro text={racking.title} />

      <div className="container">
        <div className="prod">
          <div className="prod__title hide-for-desktop">
            <h1>Storage</h1>
            <span className="ruler"></span>
          </div>
          <div className="cdo">
            <div className="prod__img">
              <LazyLoadImage src={storageIMG} alt="Shelving" />
            </div>

            <div className="prod__content">
              <div className="prod__title hide-for-mobile">
                <h1>Storage</h1>
                <span className="ruler"></span>
              </div>
              <p>
                Molomo Trading And Projects has established itself as a leader
                in providing top-quality locker and storage solutions that
                surpass the competition. Our lockers are designed with
                innovative features that set us apart from the rest, ensuring
                that your goods are secured and stored safely. We take pride in
                offering the highest quality lockers that are durable, reliable,
                and customized to meet the unique needs of your business. With
                Molomo, you can be confident that your storage needs are in good
                hands, and your satisfaction is our top priority.
              </p>
            </div>
          </div>
          <div className="prod__categories">
            <div>
              <h2 className="prod__categories-title">Other Products</h2>
              <span className="ruler"></span>
            </div>
            <div className="prod__categories-items">
              <div className="prod__categories-items__item">
                <Product
                  name="Racking"
                  image={racking2}
                  url={Constants.rackingLink}
                />
              </div>
              <div className="prod__categories-items__item center">
                <Product
                  name="Shelving"
                  image={shelving}
                  url={Constants.shelvingLink}
                />
              </div>
              <div className="prod__categories-items__item end">
                <Product
                  name="Mezzanine"
                  image={mezzanineIMG}
                  url={Constants.mezzanineLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Racking;
