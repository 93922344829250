import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import logo from "../../images/mobile/logo.webp";
import menu from "../../images/icons/icon-menu.webp";
import chevron from "../../images/icons/icon-chevron-left.svg";

import "./Header.css";
import MobileNav from "./MobileNav";
import Constants from "../../constants";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);

  const handleNav = () => {
    setIsOpen(!isOpen);

    isOpen
      ? document.body.classList.remove("no-scroll")
      : document.body.classList.add("no-scroll");
  };

  return (
    <header className="header">
      <Link to="/" className="header__logo">
        <img src={logo} alt="Molomo Logo" />
      </Link>

      <div className="header__menu hide-for-desktop" onClick={handleNav}>
        <img src={menu} alt="Hamburger menu" />
      </div>
      <ul
        className={
          isOpen
            ? "header__items open hide-for-desktop"
            : "header__items hide-for-desktop"
        }
        style={{ overflowY: isOpen ? "auto" : "hidden" }}
      >
        <li>
          <NavLink
            to="/"
            className={({ isActive }) => (isActive ? "activeM" : undefined)}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "activeM" : undefined)}
            to="/about"
          >
            About
          </NavLink>
        </li>
        <li>
          <div className="dd">
            <NavLink
              className={({ isActive }) => (isActive ? "activeM" : undefined)}
              to="/products"
            >
              Products
            </NavLink>
            <button className="span" onClick={() => setIsDisplay(!isDisplay)}>
              <img src={chevron} alt="dropdown chevron" />
            </button>
          </div>

          <div
            className="products__dropM"
            style={{ display: isDisplay ? "block" : "none" }}
          >
            <NavLink to={Constants.rackingLink}>Racking</NavLink>
            <NavLink to={Constants.shelvingLink}>Shelving</NavLink>
            <NavLink to={Constants.mezzanineLink}>Mezzanine</NavLink>
            <NavLink to={Constants.storageLink}>Storage</NavLink>
          </div>
        </li>
        <li>
          <NavLink
            className={({ isActive }) => (isActive ? "activeM" : undefined)}
            to="/contact"
          >
            Contact
          </NavLink>
        </li>
        <li>
          <a href={`tel:${Constants.phoneNumber.replace(" ", "")}`}>
            <span className="number">+{Constants.phoneNumber}</span>
          </a>
        </li>
      </ul>

      <MobileNav />
      <div
        className="backdrop"
        style={{ display: isOpen ? "block" : "none" }}
        onClick={handleNav}
      ></div>
    </header>
  );
}

export default Header;
