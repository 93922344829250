import React from "react";

import worker from "../../images/warehouse.webp";
import work from "../../images/work.webp";
import employee from "../../images/employee.webp";
import "./About.css";

import { Intro, Whatsapp } from "../";
import Footer from "../Footer/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";

import pdf from "../../pdf/B-BBEEMolomoNR.pdf";
import bIcon from "../../images/icons/BBEE.png";

function About() {
  document.body.classList.remove("no-scroll");

  return (
    <>
      <Whatsapp />
      <Intro text="About" />
      <div className="container">
        <div className="about__content">
          <div className="about__content-img">
            <LazyLoadImage src={employee} alt="construction2" />
          </div>

          <div className="about__content-text">
            <h2>Molomo NR Trading and Projects (PTY) LTD:</h2>
            <p>
              Founded in 2018, Molomo NR Trading and Projects (PTY) LTD is a
              reputable steel company based in South Africa. The company
              specializes in the manufacturing of racking, mezzanine, shelving,
              and storage solutions that meet the needs of businesses in various
              industries. With a commitment to excellence and customer
              satisfaction, Molomo NR Trading and Projects (PTY) LTD, has
              established itself as a reliable provider of high-quality steel
              products in South Africa and the Southern African Development
              Community (SADC) region.
            </p>
          </div>
        </div>
        <div className="about__content invert">
          <div className="about__content-img">
            <LazyLoadImage src={work} alt="construction2" />
          </div>

          <div className="about__content-text">
            <h2>
              Providing High-Quality Steel Storage Solutions in South Africa and
              Beyond
            </h2>
            <p>
              One of the key strengths of Molomo NR Trading and Projects (PTY)
              LTD, is its team of experienced professionals who have a wealth of
              knowledge in the steel industry. From design to installation, the
              team works closely with clients to understand their needs and
              deliver custom solutions that are both functional and
              aesthetically pleasing. The company's state-of-the-art
              manufacturing facilities are equipped with the latest technology,
              allowing them to produce steel products that are not only of the
              highest quality but also cost-effective.
            </p>
          </div>
        </div>

        <div className="about__content ">
          <div className="about__content-img">
            <LazyLoadImage src={worker} alt="construction2" />
          </div>

          <div className="about__content-text">
            <h2>Mission</h2>
            <p>
              Molomo NR Trading and Projects (PTY) LTD's commitment to quality
              is reflected in its ISO 9001 certification, which demonstrates the
              company's adherence to international quality standards. This
              certification ensures that customers receive products that meet or
              exceed their expectations in terms of durability, safety, and
              performance. Molomo NR Trading and Projects (PTY) LTD's dedication
              to excellence has earned the company a solid reputation in the
              industry. With a growing clientele in South Africa and the SADC
              region, the company is poised for further growth and success. If
              you're looking for high-quality steel storage solutions, Molomo NR
              Trading and Projects (PTY) LTD is the company to trust.
            </p>
            <br></br>
            <p>
              <b>Molomo NR is a level 1 BEE supplier </b>
            </p>
            <a className="pdff" href={pdf} target="_blank" rel="noreferrer">
              <img src={bIcon} alt="View BBEE certificate" />
            </a>
          </div>
        </div>

        <div>
          <h5>Are you in need of more storage?</h5>
          <br></br>
          <h6>
            Take Your Space to New Heights, Rack upwards, Organize with ease and
            get more Storage space with Molomo NR Trading and Projects!
          </h6>
          <br></br>
          <p>
            Just know that we have a team of experienced professionals,
            state-of-the-art manufacturing facilities, and a commitment to
            quality and customer satisfaction, the company has established
            itself as a reliable provider of high-quality steel products.
            Whether you're in need of racking, mezzanine, shelving, or other
            storage solutions, Molomo NR Trading and Projects (PTY) LTD has the
            expertise to meet your needs. Contact them today to learn more about
            how they can help your business optimize its storage space and
            improve efficiency.
          </p>
        </div>
      </div>
      <br></br>
      <Footer />
    </>
  );
}

export default About;
