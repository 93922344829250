import "./FooterStyles.css";
import "react-toastify/dist/ReactToastify.css";
import facebook from "../../images/icons/icon-facebook.webp";
import instagram from "../../images/icons/icon-instagram.webp";
import linkedIn from "../../images/icons/icon-linkedin.webp";
import whatsapp from "../../images/icons/icon-whatsapp.webp";
import logoicon from "../../images/icons/logo-icon.webp";
import BBBEE from "../../images/icons/BBEE.png";
import { ToastContainer, toast } from "react-toastify";
import { React, useState } from "react";
import axios from "axios";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import pdfFile from "../../pdf/B-BBEEMolomoNR.pdf";
import Constants from "../../constants";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    emailcc: "",
    message: "",
    subject: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(formData);
    // API that fetches the data and sends it
    setIsLoading(true);
    const request = axios.post(
      "https://molomo-email-service.onrender.com/api/sendEmail",
      formData
    );

    await toast.promise(request, {
      pending: "Sending email...",
      success: { render: "Sent successfully ", delay: 100 },
      error: {
        render: "Our systems are down please contact using alternatives",
        delay: 100,
      },
    });
    setFormData({
      name: "",
      email: "",
      message: "",
      subject: "",
    });
    setIsLoading(false);
    // .post("https://mailer-378413.wn.r.appspot.com/api/sendEmail", formData)
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="desdes">
          <div className="footer__inputs ">
            <form onSubmit={handleSubmit}>
              <h2>Message us quickly</h2>
              <label className="sr-only" htmlFor="email">
                Email
              </label>
              <input
                type="text"
                placeholder="Enter your email address"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
              <label className="sr-only" htmlFor="messenge">
                Messege
              </label>
              <textarea
                rows={3}
                placeholder="Enter your message"
                name="message"
                value={formData.message}
                onChange={handleChange}
              />
              <div className="secondBTN">
                <button type="submit" className="CTA">
                  Send
                </button>
              </div>
            </form>
            <ToastContainer />
            <div>
              <h3>Pick your enquiry</h3>
              <ul className="footer__inputs-enquiry">
                <li className="footer__inputs-enquiry-item">
                  <label className="sr-only" htmlFor="racking"></label>
                  <input
                    className="checkk"
                    type="checkbox"
                    name="racking"
                    id="racking"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <p>Racking</p>
                </li>
                <li className="footer__inputs-enquiry-item">
                  <label className="sr-only" htmlFor="shelving"></label>
                  <input
                    className="checkk"
                    type="checkbox"
                    name="shelving"
                    id="shelving"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <p>Shelving</p>
                </li>
                <li className="footer__inputs-enquiry-item">
                  <label className="sr-only" htmlFor="mezzanine"></label>
                  <input
                    className="checkk"
                    type="checkbox"
                    name="mezzanine"
                    id="mezzanine"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <p>Mezzanine</p>
                </li>
                <li className="footer__inputs-enquiry-item">
                  <label className="sr-only" htmlFor="storage"></label>
                  <input
                    className="checkk"
                    type="checkbox"
                    name="storage"
                    id="storage"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                  <p>Storage</p>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer__info">
            <div className="img-con">
              <img
                src={logoicon}
                alt="Logo"
                className="hide-for-mobile footer__logo"
              />
            </div>

            <div className="footer__info-cert">
              <a href={pdfFile}>Download our BBBEE Certificate</a>
              <a href={pdfFile}>
                <img src={BBBEE} alt="BBBEE icon" />
              </a>
            </div>

            <div className="footer__info-icons">
              <a
                href={`https://api.whatsapp.com/send?phone=${Constants.whatsapp}`}
                className="social-link"
              >
                <LazyLoadImage
                  src={whatsapp}
                  alt="whatsapp"
                  loading="lazy"
                  color="#fff"
                  className="social"
                />
              </a>
              <a href={Constants.instagram} className="social-link">
                <LazyLoadImage
                  src={instagram}
                  alt="instagram"
                  loading="lazy"
                  color="#fff"
                  className="social"
                />
              </a>
              <a href={Constants.facebook} className="social-link">
                <LazyLoadImage
                  src={facebook}
                  alt="facebook"
                  loading="lazy"
                  className="social"
                />
              </a>
              <a href={Constants.linkedin} className="social-link">
                <LazyLoadImage
                  src={linkedIn}
                  alt="linkedIn"
                  loading="lazy"
                  className="social"
                />
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div className="footer__copy">
          <div className="creators">
            <p>
              Copyright &copy; {new Date().getFullYear()} MOLOMO NR TRADING AND
              PROJECTS (PTY) LTD all rights reserved.
              <br />
              <span className="maker">
                Created by &nbsp;
                <a
                  href="mailto:bekithemba.mdluli.bm@gmail.com"
                  style={{ textDecoration: "none" }}
                >
                  Themba
                </a>
                , &nbsp;
                <a
                  href="mailto:isaac@staquitize.io"
                  style={{ textDecoration: "none" }}
                >
                  Isaac
                </a>
                &nbsp; & Gift
              </span>
            </p>
          </div>

          <ul className="footer__copy-links">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/products">Products</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
