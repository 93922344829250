import React from "react";

import { Intro, Footer, Whatsapp } from "../../components";

import rackingIMG from "../../images/bannerProds/racking.webp";

import mezzanineIMG from "../../images/products/mezzanineP.webp";

import storage from "../../images/products/storageP.webp";
import shelving from "../../images/products/shelvingP.webp";

import "../Products/Products.css";
import Product from "../../components/ProductTabs/Product";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Constants from "../../constants";

function Racking() {
  document.body.classList.remove("no-scroll");
  const racking = {
    title: "Racking",
  };

  return (
    <>
      <Whatsapp />
      <Intro text={racking.title} />

      <div className="container">
        <div className="prod">
          <div className="prod__title hide-for-desktop">
            <h1>Racking</h1>
            <span className="ruler"></span>
          </div>
          <div className="cdo">
            <div className="prod__img">
              <LazyLoadImage src={rackingIMG} alt="Shelving" />
            </div>

            <div className="prod__content">
              <div className="prod__title hide-for-mobile">
                <h1>Racking</h1>
                <span className="ruler"></span>
              </div>
              <p>
                At Molomo Trading And Projects, we understand that businesses
                have unique storage needs, which is why we offer a diverse range
                of racking options to meet those needs. Whether you're looking
                for a sturdy platform to showcase your products or a convenient
                storage solution to keep essential items within reach, we've got
                you covered.
                <br /> <br />
                Our racking systems are designed to optimize your storage space
                and streamline your operations, making it easier for you to
                manage your inventory and improve efficiency. With Molomo, you
                can trust that you're getting top-quality racking solutions that
                will meet your specific requirements and exceed your
                expectations.
              </p>
            </div>
          </div>
          <div className="prod__categories">
            <div>
              <h2 className="prod__categories-title">Other Products</h2>
              <span className="ruler"></span>
            </div>
            <div className="prod__categories-items">
              <div className="prod__categories-items__item">
                <Product
                  name="Mezzanine"
                  image={mezzanineIMG}
                  url={Constants.mezzanineLink}
                />
              </div>
              <div className="prod__categories-items__item center">
                <Product
                  name="Shelving"
                  image={shelving}
                  url={Constants.shelvingLink}
                />
              </div>
              <div className="prod__categories-items__item end">
                <Product
                  name="Storage"
                  image={storage}
                  url={Constants.storageLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Racking;
