import React from "react";

import logo from "../../images/icons/icon-whatsapp.webp";
import Constants from "../../constants";
import "./whatsapp.css";

function Whatsapp() {
  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${Constants.whatsapp}`}
      className="whatsapp"
    >
      <img src={logo} alt="whatsapp button" />
    </a>
  );
}

export default Whatsapp;
