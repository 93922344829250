const Constants = {
  phoneNumber: "27829713299",
  whatsapp: "27829713299",
  homeLink: "/",
  mezzanineLink: "/products/mezzanine",
  storageLink: "/products/storage",
  rackingLink: "/products/racking",
  shelvingLink: "/products/shelving",
  instagram: "https://www.instagram.com",
  linkedin: "https://www.linkedin.com",
  facebook: "https://www.facebook.com",
  personal: "27829713299",
};

export default Constants;
