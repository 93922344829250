import { About, ScrollToTop } from "./components";

import { Routes, Route } from "react-router-dom";
import ErrorPage from "./error-page";
import {
  Racking,
  Storage,
  Shelving,
  Mezzanine,
  Home,
  Products,
  Contact,
} from "./pages";

import "./App.css";

function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/products/racking" element={<Racking />} />
        <Route path="/products/shelving" element={<Shelving />} />
        <Route path="/products/storage" element={<Storage />} />
        <Route path="/products/mezzanine" element={<Mezzanine />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
}

export default App;
