import React from "react";

import { NavLink } from "react-router-dom";
import Constants from "../../constants";

function MobileNav() {
  return (
    <ul className={"header__itemsD hide-for-mobile"}>
      <li>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? "activeD navvv" : "navvv")}
        >
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          className={({ isActive }) => (isActive ? "activeD navvv" : "navvv")}
          to="/about"
        >
          About
        </NavLink>
      </li>
      <li className="dropL navvv">
        <NavLink
          to="/products"
          className={({ isActive }) => (isActive ? "activeD navvv" : "navvv")}
        >
          Products{" "}
          {/* <span className="drop">
            <img src={chev} alt="Drop down menu" />
          </span> */}
        </NavLink>

        <div className="products__drop">
          <NavLink to={Constants.rackingLink}>Racking</NavLink>
          <NavLink to={Constants.shelvingLink}>Shelving</NavLink>
          <NavLink to={Constants.mezzanineLink}>Mezzanine</NavLink>
          <NavLink to={Constants.storageLink}>Storage</NavLink>
        </div>
      </li>
      <li>
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? "activeD navvv" : "navvv")}
        >
          Contact
        </NavLink>
      </li>
      <li>
        <a href={`tel:${Constants.phoneNumber.replace(/\s/g, "")}`}>
          <span className="number">+{Constants.phoneNumber}</span>
        </a>
      </li>
    </ul>
  );
}

export default MobileNav;
