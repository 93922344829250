import "./ContactFormStyles.css";
import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactForm = () => {
  // const notify = () => toast(returnedData.message);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    subject: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = async (event) => {
    const { name, value } = event.target;
    setFormData((prevProps) => ({
      ...prevProps,
      [name]: value,
    }));
  };

  const handleSubmit =async  (event) => {
    event.preventDefault();
    // console.log(formData);
    // API that fetches the data and sends it
    setIsLoading(true);
    const request = axios.post("https://mailer-378413.wn.r.appspot.com/api/sendEmail", formData)

       await toast.promise( request, {
        pending: 'Sending email...',
        success: { render: "Sent successfully ", delay: 100 },
        error: { render: "Our systems are down please contact using alternatives", delay: 100 },
      });
      setFormData({
        name: "",
        email: "",
        message: "",
        subject: "",
      });
      setIsLoading(false);
          // .post("https://mailer-378413.wn.r.appspot.com/api/sendEmail", formData)

  };

  return (
    <section className="contact">
      <div className="pageName">
        <h2> Get in touch </h2>
        <span className="ruler"></span>
      </div>

      <div className="form-container">
        <div className="location">
          <h3>
            Are you searching for the perfect racking, shelving, storage, or
            mezzanine solution?
          </h3>
          <br />
          <p className="form-font">
            We're here to help! Contact us directly through this email form with
            any questions or enquiries, and one of our knowledgeable agents will
            be in touch soon.
            <br></br>
            <br></br>
            Let us guide you through the process of finding the ideal racking,
            shelving, storage, or mezzanine solution product that perfectly
            suits your business requirements.
          </p>

          <br />

          <p>
            Address:{" "}
            <a href="https://www.google.com/maps/search/7323+Zone+12+ext+Solomon+Mahlangu+Street+Sebokeng+1983/@-26.55808,27.820784,14z/data=!3m1!4b1?authuser=0&entry=ttu">
              7323 Zone 12 ext Solomon Mahlangu Street Sebokeng 1983&nbsp;
            </a>
          </p>
          <p>
            Email:{" "}
            <a href="mailto:nhlapolindiwe4@gmail.com">nhlapolindiwe4@gmail.com&nbsp;</a>
          </p>

          <p>
            Phone Number: <a href="tel:0829713299">+27 82 971 3299&nbsp;</a>
          </p>
          {/* <p>
            Alternative Phone Number:{" "}
            <a href="tel:0829713299">+27 82 971 3299&nbsp;</a>
          </p> */}
        </div>

        <form action="POST" className="contactForm" onSubmit={handleSubmit}>
          <h4>For more insight drop us an email.</h4>
          <p>
            One of our agents will be in touch shortly.
            <br></br>
            Please don't hesitate to reach out.
          </p>
          <div className="input-wrapper">
            <input
              className="form-control"
              placeholder="Enter your name"
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="input" className="control-label">
              Enter your name
            </label>
          </div>
          <div className="input-wrapper">
            <input
              className="form-control"
              placeholder="Enter your subject"
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              required
            />
            <label html-for="input" className="control-label">
              Enter your subject
            </label>
          </div>
          <div className="input-wrapper">
            <input
              className="form-control"
              placeholder="Enter your email address"
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label html-for="input" className="control-label">
              Enter your email address
            </label>
          </div>
          <div className="input-wrapper">
            <textarea
              className="form-control"
              placeholder="Enter your message"
              type="text"
              rows="7"
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              required
            />
            <label html-for="input" className="control-label">
              Enter your message
            </label>
          </div>

          <div className="btn-container">
            {isLoading ? (
              <button className="CTA" type="button" disabled>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                Sending...
              </button>
            ) : (
              <button type="submit" className="CTA">
                Send
              </button>
            )}
          </div>
        </form>
        <ToastContainer />
      </div>
    </section>
  );
};

export default ContactForm;
