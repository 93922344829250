import { ContactForm, Intro, Footer, Whatsapp } from "../../components/index";

function Contact() {
  document.body.classList.remove("no-scroll");
  return (
    <>
      <Whatsapp />
      <Intro text="Contact" />
      <div className="container">
        <ContactForm />
      </div>
      <Footer />
    </>
  );
}

export default Contact;
