import React from "react";
import Social from "./Social";

import "./Panel.css";

import instagram from "../../images/icons/icon-instagram.webp";
import linkedIn from "../../images/icons/icon-linkedin.webp";
import facebook from "../../images/icons/icon-facebook.webp";
import whatsapp from "../../images/icons/icon-whatsapp.webp";
import Constants from "../../constants";

const socialM = [
  {
    img: whatsapp,
    name: "Whatsapp",
    url: `https://api.whatsapp.com/send?phone=${Constants.whatsapp}`,
  },
  {
    img: instagram,
    name: "Instagram",
    url: Constants.instagram,
  },
  {
    img: linkedIn,
    name: "LinkedIn",
    url: Constants.linkedin,
  },
  {
    img: facebook,
    name: "Facebook",
    url: Constants.facebook,
  },
];

function Panel() {
  return (
    <div className="panel hide-for-mobile">
      {socialM.map((e, i) => (
        <Social key={i} image={e.img} name={e.name} url={e.url} />
      ))}
    </div>
  );
}

export default Panel;
