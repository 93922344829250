import { Intro, Footer, Whatsapp, ProductTabs } from "../../components";

function Products() {
  document.body.classList.remove("no-scroll");
  return (
    <>
      <Whatsapp />
      <Intro text="Products" />
      <div className="container">
        <ProductTabs />
      </div>
      <Footer />
    </>
  );
}

export default Products;
