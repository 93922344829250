import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

function ControlledCarousel({ data }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect}>
      <Carousel.Item>
        <img
          className="carousel__images "
          src={data[0].img}
          alt="First slide"
        />

        <Carousel.Caption className="carousel__text">
          <h3>Racking</h3>
          <p>{data[0].text}</p>
          <div className="carousel__text-links">
            <Link to="/products/racking">Racking</Link>
            <Link to="/contact" className="other-btn">
              Contact us
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="carousel__images "
          src={data[1].img}
          alt="Second slide"
        />

        <Carousel.Caption className="carousel__text">
          <h3>Shelving</h3>
          <p>{data[1].text}</p>

          <div className="carousel__text-links">
            <Link to="/products/shelving">Shelving</Link>
            <Link to="/contact" className="other-btn">
              Contact us
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="carousel__images "
          src={data[2].img}
          alt="Third slide"
        />

        <Carousel.Caption className="carousel__text">
          <h3>Storage</h3>
          <p>{data[2].text}</p>

          <div className="carousel__text-links">
            <Link to="/products/storage">Storage</Link>
            <Link to="/contact" className="other-btn">
              Contact us
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="carousel__images "
          src={data[3].img}
          alt="Fourth slide"
        />

        <Carousel.Caption className="carousel__text">
          <h3>Mezzanine</h3>
          <p>{data[3].text}</p>

          <div className="carousel__text-links">
            <Link to="/products/mezzanine">Mezzanine</Link>
            <Link to="/contact" className="other-btn">
              Contact us
            </Link>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
