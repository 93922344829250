import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

function Product({ name, image, url }) {
  return (
    <div className="product shine">
      <LazyLoadImage src={image} alt={name} />
      <div className="product__content">
        <h2>{name}</h2>
        <Link to={url}>Read More</Link>
      </div>
    </div>
  );
}

export default Product;
