import {
  Details,
  Header,
  Panel,
  Services,
  Footer,
} from "../../components/index";

import construction from "../../images/shelving.webp";
import welding from "../../images/racking.webp";
import wooden from "../../images/storage.webp";
import mezzanine from "../../images/mezzanine.webp";

import "./Home.css";
import ControlledCarousel from "./Carousel";

function Home() {
  const caruselData = [
    {
      id: 1,
      img: welding,
      imgMobile: welding,
      text: "Stability in every Rack",
    },
    {
      id: 2,
      img: construction,
      imgMobile: construction,
      text: "Efficiency in every Shelf",
    },

    {
      id: 3,
      img: wooden,
      imgMobile: wooden,
      text: "Store smarter, not harder",
    },
    {
      id: 4,
      img: mezzanine,
      imgMobile: mezzanine,
      text: "Find a repository on our mezzanine",
    },
  ];

  document.body.classList.remove("no-scroll");
  return (
    <div className="home">
      <div className="hero">
        <Header />
        <Panel />
        <div className="carrr" style={{ backgroundColor: "black" }}>
          <ControlledCarousel data={caruselData} />
        </div>
      </div>
      <div className="container">
        <Details />
        <Services />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
