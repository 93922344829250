import React from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholder from "../../images/aboutUs.webp";
import placeholder2 from "../../images/mobile/aboutUs.webp";

import "./Details.css";

function Details() {
  return (
    <div className="details">
      <div className="details__left">
        <h2>Efficiency in every shelf, stability in every rack.</h2>
        <p>
          At Molomo, we specialize in providing innovative and reliable shelving
          and racking solutions for businesses and individuals. Our expert team
          works to meet your unique storage needs with top-quality products and
          unparalleled customer service.
        </p>
        <div className="details__left-btn">
          <Link className="CTA" to="/contact">
            Contact
          </Link>
        </div>
      </div>

      <div className="details__right">
        <div className="details__right-img">
          {/* <img src={placeholder} alt="information" /> */}
          <LazyLoadImage
            className="hide-for-desktop"
            alt="Information"
            // height={image.height}
            // width={image.width}
            src={placeholder2} // use normal <img> attributes as props
          />

          <LazyLoadImage
            className="hide-for-mobile"
            alt="Information"
            // height={image.height}
            // width={image.width}
            src={placeholder} // use normal <img> attributes as props
          />
        </div>
      </div>
    </div>
  );
}

export default Details;
