import React from "react";

import storage from "../../images/storage32.webp";
import mezzanine from "../../images/mezzanine32.webp";
import racking from "../../images/racking32.webp";
import shelving from "../../images/shelving32.webp";

import Service from "./Service";

import "./Services.css";

function Services() {
  const services = [
    {
      title: "Racking",
      img: racking,
      text: "At Molomo Trading And Projects, we understand that businesses have unique storage needs, which is why we offer a diverse range of racking options to meet those needs. Whether you're looking for a sturdy platform to showcase your products or a convenient storage solution to keep essential items within reach, we've got you covered.",
      url: "/products/racking",
    },
    {
      title: "Shelving",
      img: shelving,
      text: "Our shelving systems are designed to effortlessly support your products in any context, whether in a retail or warehouse environment. With our commitment to quality and reliability, you can trust that our shelving solutions will meet your storage needs and exceed your expectations.",
      url: "/products/shelving",
    },
    {
      title: "Mezzanine",
      img: mezzanine,
      text: "By utilizing the available vertical space, Mezzanine Floors offer an effective way to double the surface area of a building and maximize its potential. These versatile floors can be used for a variety of purposes, including storage, fabrication, packing, and offices, among many others.",
      url: "/products/mezzanine",
    },
    {
      title: "Storage",
      img: storage,
      text: "Molomo Trading And Projects has been providing top-quality locker and storage solutions that surpass the competition. Our lockers are designed with innovative features that set us apart from the rest, ensuring that your goods are secured and stored safely.",
      url: "/products/storage",
    },
  ];

  return (
    <div className="con">
      <div>
        <h1>Products</h1>
        <span className="ruler"></span>
      </div>
      <div className="services">
        {services.map((s, i) => (
          <Service
            key={i}
            img={s.img}
            text={s.text}
            title={s.title}
            url={s.url}
          />
        ))}
      </div>
    </div>
  );
}

export default Services;
