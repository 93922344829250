import React from "react";

import { Intro, Footer, Whatsapp } from "../../components";

import mezzanineIMG from "../../images/bannerProds/mezzanine.webp";

import storage from "../../images/products/storageP.webp";
import shelving from "../../images/products/shelvingP.webp";
import racking2 from "../../images/products/rackingP.webp";

import "../Products/Products.css";
import Product from "../../components/ProductTabs/Product";

import { LazyLoadImage } from "react-lazy-load-image-component";
import Constants from "../../constants";

import "react-lazy-load-image-component/src/effects/blur.css";

function Racking() {
  document.body.classList.remove("no-scroll");
  const racking = {
    title: "Mezzanine Solutions",
  };

  return (
    <>
      <Whatsapp />
      <Intro text={racking.title} />

      <div className="container">
        <div className="prod">
          <div className="prod__title hide-for-desktop">
            <h1>Mezzanine</h1>
            <span className="ruler"></span>
          </div>
          <div className="cdo">
            <div className="prod__img">
              <LazyLoadImage effect="blur" src={mezzanineIMG} alt="Shelving" />
            </div>

            <div className="prod__content">
              <div className="prod__title hide-for-mobile">
                <h1>Mezzanine</h1>
                <span className="ruler"></span>
              </div>
              <p>
                By utilizing the available vertical space, Mezzanine Floors
                offer an effective way to double the surface area of a building
                and maximize its potential. These versatile floors can be used
                for a variety of purposes, including storage, fabrication,
                packing, and offices, among many others.
                <br /> <br />
                Mezzanine Floors allow businesses to expand their workspace
                without the need for additional real estate, making them a
                cost-effective solution for businesses looking to increase their
                capacity. With their customizable design and adaptability,
                Mezzanine Floors offer an efficient and practical way to
                optimize the functionality of any commercial or industrial
                building.
              </p>
            </div>
          </div>
          <div className="prod__categories">
            <div>
              <h2 className="prod__categories-title">Other products</h2>
              <span className="ruler"></span>
            </div>
            <div className="prod__categories-items">
              <div className="prod__categories-items__item">
                <Product
                  name="Racking"
                  image={racking2}
                  url={Constants.rackingLink}
                />
              </div>
              <div className="prod__categories-items__item center">
                <Product
                  name="Shelving"
                  image={shelving}
                  url={Constants.shelvingLink}
                />
              </div>
              <div className="prod__categories-items__item end">
                <Product
                  name="Storage"
                  image={storage}
                  url={Constants.storageLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Racking;
