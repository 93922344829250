import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import "react-lazy-load-image-component/src/effects/blur.css";

function Service({ img, title, text, url }) {
  return (
    <div className="service">
      <div className="service__img">
        <LazyLoadImage src={img} alt={title} effect="blur" />
      </div>
      <h3>{title}</h3>
      <p>{text}</p>

      <div className="bb">
        <Link to={url} className="CTA">
          See {title}
        </Link>
      </div>
    </div>
  );
}

export default Service;
