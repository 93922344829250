import React from "react";

import { Intro, Footer, Whatsapp } from "../../components";

import shelvingIMG from "../../images/bannerProds/shelving.webp";

import storageIMG from "../../images/products/storageP.webp";

import mezzanineIMG from "../../images/products/mezzanineP.webp";

import racking2 from "../../images/products/rackingP.webp";

import "../productss.css";
import Product from "../../components/ProductTabs/Product";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Constants from "../../constants";

function Racking() {
  document.body.classList.remove("no-scroll");
  const shelving = {
    title: "Shelving Solutions",
  };

  return (
    <>
      <Whatsapp />
      <Intro text={shelving.title} />
      <div className="container">
        <div className="prod">
          <div className="prod__title hide-for-desktop">
            <h1>Shelving</h1>
            <span className="ruler"></span>
          </div>
          <div className="cdo">
            <div className="prod__img">
              <LazyLoadImage src={shelvingIMG} alt="Shelving" />
            </div>

            <div className="prod__content">
              <div className="prod__title hide-for-mobile">
                <h1>Shelving</h1>
                <span className="ruler"></span>
              </div>
              <p>
                The versatility of our products is what sets them apart. We
                produce shelving solutions in a wide range of sizes and shapes
                to accommodate goods of all weights and sizes, whether light,
                medium or heavy-duty. <br /> <br />
                Our shelving systems are designed to effortlessly support your
                products in any context, whether in a retail or warehouse
                environment. With our commitment to quality and reliability, you
                can trust that our shelving solutions will meet your storage
                needs and exceed your expectations.
              </p>
            </div>
          </div>
          <div className="prod__categories">
            <div>
              <h2 className="prod__categories-title">Categories</h2>
              <span className="ruler"></span>
            </div>
            <div className="prod__categories-items">
              <div className="prod__categories-items__item">
                <Product
                  name="Racking"
                  image={racking2}
                  url={Constants.rackingLink}
                />
              </div>
              <div className="prod__categories-items__item center">
                <Product
                  name="Mezzanine"
                  image={mezzanineIMG}
                  url={Constants.mezzanineLink}
                />
              </div>
              <div className="prod__categories-items__item end">
                <Product
                  name="Storage"
                  image={storageIMG}
                  url={Constants.storageLink}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Racking;
